export default [
  {
      title: 'Billing',
      icon: 'DollarSignIcon',
      adminonly: true,
      children: [
        {
          title: 'Buy Credits',
          route: 'buy-credits',
          adminonly: true,
        },
        {
          title: 'Invoices',
          route: 'invoices',
          adminonly: true,
        },
        {
          title: 'Transaction History',
          route: 'transaction-history',
          adminonly: true,
        },
      ],
    }
  ];