export default [
{
    title: 'SMS',
    icon: 'MessageCircleIcon',
    children: [
        {
        title: 'Send New SMS',
        route: 'compose-message',
        },
        {
            title: 'Outbox',
            route: 'outbox',
        },
        {
            title: 'Scheduled',
            route: 'scheduled-messages',
        },
    ],
    }
];