export default [
  {
      title: 'Voice',
      icon: 'MicIcon',
      children: [
          {
          title: 'Send New Voice',
          route: 'compose-voice',
          },
          {
              title: 'Outbox',
              route: 'outbox-voice',
          },
          {
              title: 'Scheduled',
              route: 'scheduled-voices',
          },
      ],
      }
  ];