export default [
  {
      title: 'Team',
      icon: 'UsersIcon',
      children: [
        {
          title: 'Users',
          route: 'team-users',
          adminonly: true,
        },
        {
          title: 'Requests',
          route: 'team-requests',
          adminonly: true,
        },
        {
          title: 'Accounts',
          route: 'team-accounts',
        },
      ],
    }
  ];