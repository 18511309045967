<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <b-button
            variant="primary"
            class="mr-2 mr-md-3 shadow-none"
            style="cursor: default"
            @click.stop.prevent
          >
            Balance: KSH {{ userBalance }}
          </b-button>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user.first_name }} {{ user.last_name }}
            </p>
            <div v-if="currentAccount.PersonalAccount == 'true'">
              <span class="user-status" v-if="user.postpaid">Postpaid</span>
              <span class="user-status" v-else>Prepaid</span>
            </div>
            <div v-else>
              <span class="user-status" v-if="user.postpaid">{{ currentAccount.Account }} (Postpaid)</span>
              <span class="user-status" v-else>{{ currentAccount.Account }} (Prepaid)</span>
            </div>
            
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.image ? appUrl + '/uploads/profile-pictures/' +  user.image : require('@/assets/images/avatars/default-profile-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ name: 'profile'}">
          <feather-icon size="16" icon="UserIcon" class="mr-50" />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import { getUserData, getUserBalance, getUserAccount } from "@/auth/utils";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";


export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    const { appUrl } = $themeConfig.app;
    return {
      user: null,
      currentAccount: null,
      userBalance: null,
      checkUserBalanceInterval: null,
      appUrl: appUrl,
    };
  },
  methods: {
    logout() {
      this.$http
        .get("/logout")
        .then((response) => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

          // Remove userData from localStorage
          localStorage.removeItem("userData");
          clearInterval(this.checkUserBalanceInterval);
          this.$router
            .push({
              name: "login",
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            });
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
        });
    },
    checkUserBalance() {
      this.$http.get("/user/balance").then((res) => {
        localStorage.setItem("balance", res.data.data);
        this.userBalance = getUserBalance();
      });
    },
  },
  created() {
    this.user = getUserData();
    this.userBalance = getUserBalance();
    this.currentAccount = getUserAccount();
  },
  mounted() {
    this.checkUserBalanceInterval = setInterval(() => {
      this.checkUserBalance();
    }, 60000);

    

    this.$root.$on("account-switched", () => {
      this.user = getUserData();
      this.currentAccount = getUserAccount();

      clearInterval(this.checkUserBalanceInterval);
      this.userBalance = getUserBalance();
      this.checkUserBalanceInterval = setInterval(() => {
        this.checkUserBalance();
      }, 60000);
    });


    this.$root.$on("update-user-balance", () => {
      clearInterval(this.checkUserBalanceInterval);
      this.userBalance = getUserBalance();
      this.checkUserBalanceInterval = setInterval(() => {
        this.checkUserBalance();
      }, 60000);
    });

    let timeout;
    let refresh = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.$http
          .get("/logout")
          .then((_) => {
            // Remove userData from localStorage
            // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(
              useJwt.jwtConfig.storageRefreshTokenKeyName
            );

            // Remove userData from localStorage
            localStorage.removeItem("userData");
            localStorage.removeItem("balance");
            clearInterval(this.checkUserBalanceInterval);
            this.$router
              .push({
                name: "login",
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Loggged Out Due To Inactivity",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              });
          })
          .catch((error) => {
            for (let err of error.response.data.error) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: err,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            }
          });
      }, 10 * 60 * 1000);
    };
    refresh();
    document.addEventListener("click", refresh);
  },
  beforeDestroy(){
    clearInterval(this.checkUserBalanceInterval);
  }
};
</script>
