
import dashboard from './dashboard'
import contact from './contact'
import senderId from './sender-id'
import billing from './billing'
import message from './message'
import voice from './voice'
import team from './team'
import developer from './developer'
import profile from './profile'
import logout from './logout'

export default [
  {
    header:'Pepea SMS Menu'
  },
  ...dashboard,
  ...message,
  ...voice,
  ...billing,
  ...senderId,
  ...contact,
  ...team,
  ...developer,
  ...profile,
  ...logout,
]
