export default [
{
    title: 'Contacts',
    icon: 'PhoneCallIcon',
    children: [
      {
        title: 'All Contacts',
        route: 'client-all-contacts',
      },
      {
        title: 'Invalid Contacts',
        route: 'client-invalid-contacts',
      },
      {
        title: 'Groups',
        route: 'client-groups',
      },
    ],
  }
];